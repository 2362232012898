const data = {
  en: {
    login1: 'Please provide email and password',
    login2: 'Incorrect email or password',
    signup1: 'user with this email already exists',
    signup2: 'verification email already sent [#1]',
    signUpConfirm1: 'This code has expired. Please sign up again.',
    signUpConfirm2: 'This code is incorrect. Please check your code and try again.',
    forgotPassword1: 'There is no user with that email address',
    forgotPassword2:
      'The code has already been sent. Check your spam, check you email and if that does not work try again later',
    forgotPassword3: 'There was an error sending the email, try again later',
    protect1: 'You are not logged in! Please log in to get access.',
    protect2: 'The user belonging to this token no longer exists',
    protect3: 'User recently changed password! Please log in again!',
    protect4: 'Your token has expired!',
    admin1: 'This route requires admin access!',
    resetPassword1:
      'Reset code is only valide for 10 minutes and has expired.\nPlease request new reset code',
    resetPassword2:
      'Password too simple. Please include a letter, ' + 'a digit and a special character',
    resetPassword3: 'The passwords do not match. Please try again.',
    flagDictionaryEntry1: 'You have already flagged this word',
    prodError1: 'Something went wrong 😣\nPlease contact support',
    prodError2: 'Error',
    limiter1: 'Too many requests from this IP, please try again in one hour',
    limiter2:
      'You have already signed up. You can only sign up one account ' +
      'from the same IP in a 6 hour window. Please try again later.',
    limiter3:
      'Reset password link has already been sent. You can only reset ' +
      'your password once every 24 hours. Please try again later ' +
      'or contact support',
    limiter4: 'For security, we only allow 3 attempts to submit security codes',
    changeDictionary: 'This dictionary is no longer available',
    deleteAccount: 'Password is incorrect',
    notnewuser: 'Not a new user. Please contact support',
    updateTicket:
      'Solutions are not valid. Solutions must contain at least some characters and you need to provide at least 1 solution',
  },
  pt: {
    login1: 'por favor forneça e-mail e senha',
    login2: 'senha ou email incorreto',
    signup1: 'já existe usuário com este endereço de email',
    signup2: 'e-mail de verificação já enviado [#1]',
    signUpConfirm1: 'Este código expirou. Por favor, inscreva-se novamente.',
    signUpConfirm2: 'Este código está incorreto. Verifique seu código e tente novamente.',
    forgotPassword1: 'Não há nenhum usuário com esse endereço de e-mail',
    forgotPassword2:
      'O código já foi enviado. Verifique seu spam, verifique seu e-mail e se não funcionar tente novamente mais tarde',
    forgotPassword3: 'Ocorreu um erro ao enviar o e-mail, tente novamente mais tarde',
    protect1: 'Você não está logado! Faça login para obter acesso.',
    protect2: 'O usuário pertencente a este token não existe mais',
    protect3: 'Usuário alterou a senha recentemente! Por favor faça login novamente!',
    protect4: 'Your token has expired!',
    admin1: 'Esta rota requer acesso de administrador!',
    resetPassword1:
      'Seu código de redefinição é válido por apenas 10 minutos e expirou.\nPor favor, solicite um novo código de redefinição.',
    resetPassword2: 'Senha muito simples. Inclua uma letra, um dígito e ' + 'um caractere especial',
    resetPassword3: 'As senhas não combinam. Por favor, tente novamente.',
    flagDictionaryEntry1: 'Você já marcou esta palavra',
    prodError1: 'Algo deu errado 😣\nPor favor, entre em contato com o suporte.',
    prodError2: 'Erro',
    limiter1: 'Muitas solicitações deste IP, tente novamente em uma hora',
    limiter2:
      'Você já se inscreveu. Você só pode inscrever uma conta do mesmo IP ' +
      'em uma janela de 6 horas. Por favor, tente novamente mais tarde.',
    limiter3:
      'O link de redefinição de senha já foi enviado. Você só pode redefinir ' +
      'sua senha uma vez a cada 24 horas. Tente novamente mais tarde ou entre ' +
      'em contato com o suporte',
    limiter3:
      'Por segurança, permitimos apenas três tentativas de envio de códigos ' + 'de segurança',
    changeDictionary: 'Este dicionário não está mais disponível',
    deleteAccount: 'Senha incorreto',
    notnewuser: 'Não é um novo usuário. Por favor, entre em contato com o suporte',
    updateTicket:
      'Soluções não são válidas. Soluções devem conter pelo menos alguns caracteres e você precisa fornecer pelo menos 1 solução',
  },
};

const errorMessage = (language, key) => {
  const fallbackLanguage = 'en';
  const selectedLanguage = data[language] ? language : fallbackLanguage;

  return data[selectedLanguage][key] || data[fallbackLanguage][key] || 'Invalid error code';
};

module.exports = errorMessage;
